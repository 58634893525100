import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ReactWebChat, {
  Components,
  createStore,
  hooks,
} from "botframework-webchat";
import "./ChatBot.css";
import logo from "../src/imgs/mainlogo.png";
import plus from "../src/imgs/plus.png";
import mail from "../src/imgs/mail.png";
import whitemail from "../src/imgs/whitemail.png";
import whiteplus from "../src/imgs/whiteplus.png";

import sendButton from "../src/imgs/send.png";
import activesendButton from "../src/imgs/activesend.png";
import ReactMarkdown from "react-markdown";
// import MicrophoneIcon from "../src/imgs/Recording-icon2.png";
import MicrophoneIcon from "../src/imgs/microphone-filled-svgrepo-com.svg";
import link from "../src/imgs/link.png";
import share from "../src/imgs/share.png";
import copy from "../src/imgs/copy.png";
import redo from "../src/imgs/redo.png";
import like from "../src/imgs/like.png";
import dislike from "../src/imgs/dislike.png";
import Modal from "./Modal";
import telegram from "../src/imgs/Telegram_2019_Logo500px.png";
import emailicon from "../src/imgs/email icon.jpg";
import twitter from "../src/imgs/X-Logo.png";
import chamber from "../src/imgs/chamber.png";
import spinner from "../src/imgs/spinner.gif";
import {
  WhatsappShareButton,
  WhatsappIcon,
  TwitterShareButton,
  EmailShareButton,
  TelegramShareButton,
} from "react-share";
import { ToastContainer, ToastOptions, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomMicrophoneButton from "./microphone/CustomMicrophoneButton";
import CustomDictationInterims from "./microphone/CustomDictationInterims";
import { send } from "process";
import { useLocation } from "react-router-dom";

interface ChatBotProps {
  recognizedText: string;
  lastRecognizedText: string;
  startDictate: () => void;
  stopDictate: () => void;
}

const ChatBot: React.FC<ChatBotProps> = ({
  recognizedText,
  lastRecognizedText,
  startDictate,
  stopDictate,
}) => {
  const { AdaptiveCardContent } = Components;

  const userAvatar =
    "https://www.pngplay.com/wp-content/uploads/12/User-Avatar-Profile-Transparent-Clip-Art-Background.png";
  const botAvatar =
    "https://static.vecteezy.com/system/resources/previews/010/054/157/non_2x/chat-bot-robot-avatar-in-circle-round-shape-isolated-on-white-background-stock-illustration-ai-technology-futuristic-helper-communication-conversation-concept-in-flat-style-vector.jpg";

  const {
    useActivities,
    useSendMessage,
    useSendEvent,
    useConnectivityStatus,
    useStyleSet,
    usePostActivity,
    useDictateState,
  } = hooks;

  const [dictateState] = useDictateState();

  const [isHidden, setIsHidden] = useState(false);

  const styleSet = useStyleSet();

  const sendMessage = useSendMessage();

  const sendEvent = useSendEvent();
  const sendActivity = usePostActivity();
  const [activities] = useActivities();
  const [connectivity] = useConnectivityStatus();
  const [userMessage, setUserMessage] = useState("");
  const [email, setEmail] = useState("");
  const [submitEmail, setsubmitEmail] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [isRecording, setIsRecording] = useState(false);

  const [dots, setDots] = useState("");
  const [emailval, setEmailval] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [sentFlag, setsentFlag] = useState(false);
  const [loader, setloader] = useState(false);

  const handleInterimChange = (interim: string) => {
    setUserMessage(interim);
  };

  const [isComponentLoaded, setIsComponentLoaded] = useState(false);

  async function handleStartDictate() {
    try {
      // Check microphone permission
      const permissionStatus = await navigator.permissions.query({
        name: "microphone" as PermissionName,
      });

      if (permissionStatus.state === "denied") {
        alert(
          "Microphone access is blocked. Please allow microphone access in your browser settings."
        );
        return;
      }

      // Try accessing the microphone to catch errors
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      stream.getTracks().forEach((track) => track.stop()); // Close the stream after checking

      startDictate();
      setIsRecording(true);
    } catch (error) {
      alert(
        "Microphone access is blocked or unavailable. Please allow it in your browser settings."
      );
      console.error("Microphone access error:", error);
    }
  }

  async function handleStopDictate() {
    await stopDictate();
    setIsRecording(false);
    setUserMessage("");
  }

  useEffect(() => {
    console.log("lastRecognizedText 0000", lastRecognizedText);
    console.log("recognizedText 0000", recognizedText);

    setUserMessage(lastRecognizedText + "" + recognizedText);
  }, [lastRecognizedText, recognizedText]);

  useEffect(() => {
    if (!isComponentLoaded) {
      setIsComponentLoaded(true); // Mark the component as loaded
      return; // Prevent execution on first render
    }

    console.log("dictateState 9999", dictateState);
    if (dictateState === 0) {
      setIsRecording(false);
      console.log("dictateState 9999xx", userMessage);
      // if (userMessage.trim() !== "") {
      //   sendMessage(userMessage);
      //   setUserMessage("");
      //   setIsHidden(true);
      // }
    } else if (dictateState === 3) {
      setIsRecording(true);
    }
  }, [dictateState]);

  const [isAudio, setIsAudio] = useState(false);
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get("voiceEnabled") === "true") {
      setIsAudio(true);
      console.log("Query param detected: isAudio=true", isAudio);
    }
  }, []);

  useEffect(() => {
    let interval: any;
    if (isTyping || isRecording) {
      interval = setInterval(() => {
        setDots((prevDots) => (prevDots.length < 3 ? prevDots + "." : ""));
      }, 500);
    } else {
      setDots(""); // Reset dots when not typing
    }

    return () => clearInterval(interval); // Cleanup the interval on unmount
  }, [isTyping, isRecording]);

  const [hiddenButtons, setHiddenButtons] = useState<any[]>([]);

  const quickClick = useCallback(
    async (value: any, id: any) => {
      console.log("quickClick xcxc", value);
      setIsHidden(true);

      sendMessage(value);

      console.log("hiddenButtons old is:", hiddenButtons);

      console.log("id is:", id);

      // Only update state if the activity.id is not already in likeIndexCollection
      setHiddenButtons((prev) => {
        if (!prev.includes(id)) {
          const newButtons = [...prev, id];

          console.log("hiddenButtons updated to:", newButtons); // Log after state is computed

          return newButtons;
        }
        return prev; // No state change if already liked
      });
      console.log("hiddenButtons is:", hiddenButtons);
    },
    [sendMessage, hiddenButtons]
  );

  function showVal() {
    console.log("check:", hiddenButtons);
  }

  // const [hiddenButtons, setHiddenButtons] = useState<any[]>([""]);

  // function quickClick(value: any, id: any) {
  //   setIsHidden(true);
  //   console.log("hiddenButtons old is:", hiddenButtons);

  //   console.log("id is:", id);
  //   // setHiddenButtons((prev) => [...prev, id]);
  //   setHiddenButtons(id);

  //   console.log("hiddenButtons is:", hiddenButtons);

  //   sendMessage(value);
  // }

  // const handleSendMessageempty = useCallback(() => {
  //   console.log("empty message");
  //   sendMessage("   ");

  //   setIdleFlag((prev) => {
  //     if (!prev) {
  //       return true;
  //     }
  //     return prev;
  //   });
  // }, [sendMessage]);

  const handleSendMessage = useCallback(() => {
    if (userMessage.trim()) {
      setIsHidden(true);
      sendMessage(userMessage);
      setUserMessage(""); // Clear input after sending
      handleStopDictate();
    }
  }, [sendMessage, userMessage]);

  const callSendEvent = useCallback(() => {
    const BotToken = localStorage.getItem("BotToken");
    console.log("Sending event", BotToken);

    sendEvent("summarize", BotToken);
    // Set a timeout to call sendMessage after 2 seconds
    // setTimeout(() => {
    //   sendMessage("history");
    // }, 2000); // 2000 milliseconds = 2 seconds
  }, [sendEvent]);

  const [summaryFlag, setsummaryFlag] = useState(false);

  function callEmailSendEvent() {
    if (emailval.length === 0) {
      setIsValid(false);
    } else {
      setloader(true);
      const BotToken = localStorage.getItem("BotToken");
      sendEvent("EmailSummary", {
        token: BotToken,
        email: emailval,
      });
    }
  }
  function callCancelEmail() {
    setloader(false);
    setEmailval("");
    setIsValid(true);
    handleCloseEmailModal();
  }
  const callRegenerateSendEvent = useCallback(
    (text: string) => {
      console.log("callRegenerateSendEvent");

      sendEvent("Regenerate", text);
    },
    [sendEvent]
  );

  const [likeIndexCollection, setLikeIndexCollection] = useState<any[]>([]);

  const callSendLikeFeedbackEvent = useCallback(
    (activity: any) => {
      // Only update state if the activity.id is not already in likeIndexCollection
      setLikeIndexCollection((prev) => {
        if (!prev.includes(activity.id)) {
          // Send feedback event if we're adding a new like
          sendEvent("Feedback", {
            ConversationId: activity.conversation.id,
            Feedbackresult: "good",
            MessageId: activity.id,
          });
          return [...prev, activity.id];
        }
        return prev; // No state change if already liked
      });
    },
    [sendEvent]
  );
  const [dislikeIndexCollection, setdislikeIndexCollection] = useState<any[]>(
    []
  );

  const callSendDisikeFeedbackEvent = useCallback(
    (activity: any) => {
      setdislikeIndexCollection((prev) => {
        if (!prev.includes(activity.id)) {
          // Send feedback event if we're adding a new dislike
          sendEvent("Feedback", {
            ConversationId: activity.conversation.id,
            Feedbackresult: "bad",
            MessageId: activity.id,
          });
          return [...prev, activity.id];
        }
        return prev; // No state change if already disliked
      });
    },
    [sendEvent]
  );

  interface WebChatActivity {
    name: string;
    type: string;
    value: any; // You can specify a more specific type if needed
  }

  const handlePostActivity = useCallback(() => {
    console.log("handlePostActivity");
    const BotToken = localStorage.getItem("BotToken");
    console.log("BotToken", BotToken);
    // Example of sending an activity
    // const activity: WebChatActivity = {
    //   name: "Token",
    //   type: "event",
    //   value: BotToken, // Assuming BotToken is defined elsewhere
    // };
    // sendActivity(activity);
  }, [sendActivity]);

  const callActivities = useCallback(() => {
    console.log("Activities:", activities);
  }, [activities]);

  const callConnectivity = useCallback(() => {
    console.log("Connectivity status:", connectivity);
  }, [connectivity]);
  const formatTimestamp = (timestamp: any) => {
    const date = new Date(timestamp);
    return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }); // Format as needed
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent default Enter key behavior (e.g., form submission)
      handleSendMessage();
    }
  };
  const scrollTargetRef = useRef<HTMLDivElement>(null);

  const handleScrollToTarget = () => {
    if (scrollTargetRef.current) {
      scrollTargetRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const submitEmailAddress = () => {
    setsubmitEmail(true);
  };

  const isFirstRender = useRef(true);

  useEffect(() => {
    // if (isFirstRender.current) {
    //   isFirstRender.current = false; // Set to false on first render
    // } else {
    handleScrollToTarget(); // Call the function on subsequent renders
    // }
    if (activities.length > 0) {
      const lastActivity = activities[activities.length - 1];
      console.log("Adaptive Card action received:", lastActivity);

      setIsTyping(lastActivity.type === "typing"); // Set isTyping to true if the last activity is typing
      // if (idleFlag) {
      //   setIdleFlag(false);
      // }
      if (
        lastActivity.type === "event" &&
        lastActivity.name === "EmailSummaryResult" &&
        lastActivity.value
      ) {
        setloader(false);
        setsummaryFlag(true);
        setsentFlag(true);
        setEmailval("");
      }
      if (
        lastActivity.type === "event" &&
        lastActivity.name === "EmailSummaryResult" &&
        !lastActivity.value
      ) {
        setloader(false);
        setsummaryFlag(true);
        setsentFlag(false);
      }
    } else {
      setIsTyping(false); // No activities means not typing
    }
  }, [activities]);

  const [connection, setConnection] = useState("Disconnected");

  useEffect(() => {
    setConnection(connectivity);
  }, [connectivity]);

  const [isModalOpen, setModalOpen] = useState(false);
  const [isChatModalOpen, setisChatModalOpen] = useState(false);
  const [isEmailodalOpen, setisEmailModalOpen] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
    setsubmitEmail(false);
  };

  const handleOpenChatModal = () => {
    setisChatModalOpen(true);
  };

  const handleOpenEmailModal = () => {
    setisEmailModalOpen(true);
    setsummaryFlag(false);
  };

  const handleCloseModal = () => setModalOpen(false);

  const handleCloseChatModal = () => setisChatModalOpen(false);
  const handleCloseEmailModal = () => setisEmailModalOpen(false);

  const [key, setKey] = useState(0);

  const [srcmail, setSrcmail] = useState(mail);

  const handleMouseEnter = () => {
    setSrcmail(whitemail);
  };

  const [srcplus, setSrcplus] = useState(plus);

  const handleMouseEnterPlus = () => {
    setSrcplus(whiteplus);
  };

  const handleMouseLeavePlus = () => {
    setSrcplus(plus);
  };
  const newChatClick = () => {
    window.location.reload();
  };

  const handleCopyToClipboard = (text: any) => {
    notify();

    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          console.log("Text copied to clipboard:", text);
        })
        .catch((err) => {
          console.error("Failed to copy text: ", err);
        });
    } else {
      // Fallback method
      const textarea = document.createElement("textarea");
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();
      try {
        document.execCommand("copy");
        console.log("Text copied to clipboard:", text);
      } catch (err) {
        console.error("Failed to copy text: ", err);
      } finally {
        document.body.removeChild(textarea);
      }
    }
  };

  const [title, setTile] = useState("");

  const handleShare = (text: any) => {
    console.log(text);
    setTile(text);
    handleOpenModal();
  };
  const notify = () => {
    const options: ToastOptions = {
      position: "top-center", // Position of the toast
      autoClose: 1000, // Auto close after 1 second
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      className: "custom-toast", // Apply custom class for styling
    };

    toast("Copied!", options);
  };

  const handleMouseLeave = () => {
    setSrcmail(mail);
  };

  // Handle change in email input
  const handleEmailChange = (e: any) => {
    const value = e.target.value;

    console.log("val: ", value);
    setEmailval(value);

    // Basic email validation (optional)
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    setIsValid(emailRegex.test(value)); // Check if email matches regex pattern
  };

  useEffect(() => {
    // Select all anchor tags and set the target="_blank" attribute
    const links = document.querySelectorAll("a");
    links.forEach((link) => {
      link.setAttribute("target", "_blank");
    });
  }, [activities]); // Empty dependency array ensures this runs only once after initial render

  return (
    <>
      {/* <div style={{ padding: "50px" }}>
        <div
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseLeave}
          style={{
            width: "200px",
            height: "200px",
            backgroundColor: "lightblue",
            textAlign: "center",
            lineHeight: "200px",
            cursor: "pointer",
          }}
        >
          Long Click Me
        </div>

        {isVisible && (
          <div
            ref={divRef} // Set ref to the div we want to monitor for outside clicks
            style={{
              marginTop: "20px",
              padding: "20px",
              backgroundColor: "lightcoral",
              textAlign: "center",
            }}
          >
            Hello! I appeared on a long click!
          </div>
        )}
      </div> */}

      <div>
        {/* <h1>React Toastify Example</h1>
        <button onClick={notify}>Show Notification</button> */}
        {/* <button onClick={showVal}>Show showVal</button> */}

        <ToastContainer />
      </div>

      {/* <button onClick={callActivities}>Log Connectivity</button> */}

      {/* <button onClick={shareOnWhatsApp}>Share on WhatsApp</button> */}
      {/* <button onClick={handleOpenModal}>hellooooo</button> */}

      {/* <button onClick={callEmailSendEvent}>callEmailSendEvent</button> */}
      <div className="wrapper">
        <div className="flexheader">
          <div className="hiddendv"></div>
          <div className="fleximage">
            {" "}
            <img className="flexbgimg" src={logo} />
          </div>

          <div className="flexiconsheader">
            {activities.length > 0 ? (
              <>
                <img
                  className="mailimg"
                  src={whitemail}
                  onClick={handleOpenEmailModal}
                />
                <img
                  className="plusimg"
                  src={whiteplus}
                  onClick={handleOpenChatModal}
                />
              </>
            ) : (
              <>
                <img className="mailimghidden" src={whitemail} />
                <img className="plusimghidden" src={whiteplus} />
              </>
            )}
          </div>
        </div>

        {!isHidden ? (
          <>
            {" "}
            <div className="flextitle showmobtitle">
              <div className="titlebg">
                The Dubai Chamber of Digital Economy helps businesses grow in
                Dubai.This AI tool can help answer your pressing questions about
                how we can help.
              </div>
            </div>
            <div className="flextitle hidemobtitle">
              <div className="titlebg2">
                The Dubai Chamber of Digital Economy helps businesses grow in
                Dubai.
              </div>
              <div className="titlebg2">
                This AI tool can help answer your pressing questions about how
                we can help.
              </div>
            </div>
          </>
        ) : (
          <></>
        )}

        <div
          className={
            activities.length > 0
              ? "chat-container container1"
              : "chat-container container0"
          }
        >
          <div className="chatboxbg">
            {!isHidden ? (
              <>
                <div className="flexallmob">
                  {" "}
                  <img
                    src={chamber}
                    className="chamber flexsuggesstionsnew"
                    alt="chamber"
                  ></img>
                  <div className="flexsuggesstions flexsuggesstionsnew">
                    <div
                      className="suggesstionbuttonsug"
                      onClick={() =>
                        quickClick(
                          "What is the Dubai Chamber of Digital Economy?",
                          ""
                        )
                      }
                    >
                      <div className="suggesstionbuttontxtsug">
                        What is the Dubai Chamber of Digital Economy?
                      </div>{" "}
                    </div>

                    <div
                      className="suggesstionbuttonsug"
                      onClick={() => quickClick("What is Create Apps?", "")}
                    >
                      <div className="suggesstionbuttontxtsug">
                        What is Create Apps?
                      </div>{" "}
                    </div>

                    <div
                      className="suggesstionbuttonsug"
                      onClick={() =>
                        quickClick("What Programs do you have?", "")
                      }
                    >
                      <div className="suggesstionbuttontxtsug">
                        What Programs do you have?
                      </div>{" "}
                    </div>

                    <div
                      className="suggesstionbuttonsug"
                      onClick={() =>
                        quickClick("Can Business in Dubai Program help me?", "")
                      }
                    >
                      <div className="suggesstionbuttontxtsug">
                        Can Business in Dubai Program help me?
                      </div>{" "}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}

            {!isHidden ? (
              <>
                <div className="flexsuggesstions flexsuggesstionsx">
                  <img src={chamber} className="chamber" alt="chamber"></img>
                  <div className="suggestionButtons">
                    <div
                      className="suggesstionbuttonsug"
                      onClick={() =>
                        quickClick(
                          "What is the Dubai Chamber of Digital Economy?",
                          ""
                        )
                      }
                    >
                      <div className="suggesstionbuttontxtsug">
                        What is the Dubai Chamber of Digital Economy?
                      </div>{" "}
                    </div>

                    <div
                      className="suggesstionbuttonsug"
                      onClick={() => quickClick("What is Create Apps?", "")}
                    >
                      <div className="suggesstionbuttontxtsug">
                        What is Create Apps?
                      </div>{" "}
                    </div>

                    <div
                      className="suggesstionbuttonsug"
                      onClick={() =>
                        quickClick("What Programs do you have?", "")
                      }
                    >
                      <div className="suggesstionbuttontxtsug">
                        What Programs do you have?
                      </div>{" "}
                    </div>

                    <div
                      className="suggesstionbuttonsug"
                      onClick={() =>
                        quickClick("Can Business in Dubai Program help me?", "")
                      }
                    >
                      <div className="suggesstionbuttontxtsug">
                        Can Business in Dubai Program help me?
                      </div>{" "}
                    </div>
                  </div>
                </div>{" "}
              </>
            ) : (
              <></>
            )}

            {activities.map((activity) => {
              const isUserMessage = activity.from.role === "user"; // Adjust according to your user ID
              const isMessage = activity.type === "message"; // Adjust according to your user ID
              const isTypingVal = activity.type === "typing"; // Adjust according to your user ID
              // if (idleFlag) return null;

              if (!isMessage) return null;

              const attachments = activity.attachments ?? [];
              const hasAttachments = attachments.length > 0;
              const firstAttachment = hasAttachments ? attachments[0] : null;

              if (activity.suggestedActions) {
                return (
                  <>
                    {" "}
                    <div className="flexbot" key={activity.id}>
                      <div className="suggesstionbutton botbuttonmarkdown">
                        <div className="suggesstionbuttontxt">
                          <ReactMarkdown>{activity.text}</ReactMarkdown>
                        </div>{" "}
                      </div>
                    </div>
                    <div
                      className="flexbot flexsuggestionbuttons"
                      id={activity.id}
                    >
                      {activity.suggestedActions?.actions?.map(
                        (element, index) =>
                          hiddenButtons.includes(activity.id) ? (
                            <></> // Empty fragment when condition is met
                          ) : (
                            <div
                              key={index}
                              className="suggesstionbutton botbutton suggestionstyle"
                              onClick={() =>
                                quickClick(element.value, activity.id)
                              }
                            >
                              <div className="suggesstionbuttontxt suggesstionbuttontxtstyle">
                                {element.value}
                              </div>
                            </div>
                          )
                      )}
                    </div>
                  </>
                );
              }
              if (hasAttachments && firstAttachment) {
                return (
                  <>
                    {" "}
                    <div className="flexbot" key={activity.id}>
                      <div className="suggesstionbutton botbuttonmarkdown">
                        <div className="suggesstionbuttontxt">
                          <AdaptiveCardContent
                            actionPerformedClassName="AdaptiveCardContentstyle"
                            content={firstAttachment.content} // Safe access to content
                          />
                        </div>{" "}
                      </div>{" "}
                    </div>
                    <div className="flexbot" key={activity.id}>
                      <div className="suggesstionbutton botbuttonmarkdown">
                        <div className="suggesstionbuttontxt">
                          {" "}
                          <ReactMarkdown>{activity.value}</ReactMarkdown>
                        </div>{" "}
                      </div>
                    </div>
                    <div className="flexixons">
                      {/* <img src={link} className="iconsstyle" /> */}
                      <img
                        src={copy}
                        className="iconsstyle"
                        onClick={() => handleCopyToClipboard(activity.text)}
                      />
                      <img
                        src={share}
                        className="iconsstyle sharenew"
                        onClick={() => handleShare(activity.text)}
                      />
                      <img
                        src={redo}
                        className="iconsstyle reloadnew"
                        onClick={() => callRegenerateSendEvent(activity.value)}
                      />

                      <img
                        src={like}
                        className={`iconsstyle ${
                          likeIndexCollection.includes(activity.id?.trim())
                            ? "iconsstyleliked"
                            : ""
                        }`}
                        onClick={() => {
                          if (
                            !dislikeIndexCollection.includes(
                              activity.id?.trim()
                            )
                          ) {
                            callSendLikeFeedbackEvent(activity);
                          }
                        }}
                        alt="like"
                      />
                      <img
                        src={dislike}
                        className={`iconsstyle ${
                          dislikeIndexCollection.includes(activity.id?.trim())
                            ? "iconsstyleliked"
                            : ""
                        }`}
                        onClick={() => {
                          if (
                            !likeIndexCollection.includes(activity.id?.trim())
                          ) {
                            callSendDisikeFeedbackEvent(activity);
                          }
                        }}
                        alt="dislike"
                      />
                    </div>
                  </>
                );
              }

              return isUserMessage ? (
                activity.text && activity.text.length > 0 && (
                  <div className="flexuser" key={activity.id}>
                    <div className="suggesstionbutton userbutton">
                      <div className="suggesstionbuttontxt">
                        {activity.text}
                      </div>{" "}
                    </div>
                  </div>
                )
              ) : (
                <>
                  <div className="flexbot" key={activity.id}>
                    <div className="suggesstionbutton botbuttonmarkdown">
                      <div className="suggesstionbuttontxt">
                        {" "}
                        <ReactMarkdown>{activity.text}</ReactMarkdown>
                      </div>{" "}
                    </div>
                  </div>
                  <div className="flexixons">
                    {/* <img src={link} className="iconsstyle" /> */}
                    <img
                      src={copy}
                      className="iconsstyle"
                      onClick={() => handleCopyToClipboard(activity.text)}
                    />{" "}
                    <img
                      src={share}
                      className="iconsstyle sharenew"
                      onClick={() => handleShare(activity.text)}
                    />
                    <img
                      src={redo}
                      className="iconsstyle reloadnew"
                      onClick={() => callRegenerateSendEvent(activity.value)}
                    />
                    <img
                      src={like}
                      className={`iconsstyle ${
                        likeIndexCollection.includes(activity.id?.trim())
                          ? "iconsstyleliked"
                          : ""
                      }`}
                      onClick={() => {
                        if (
                          !dislikeIndexCollection.includes(activity.id?.trim())
                        ) {
                          callSendLikeFeedbackEvent(activity);
                        }
                      }}
                      alt="like"
                    />
                    <img
                      src={dislike}
                      className={`iconsstyle ${
                        dislikeIndexCollection.includes(activity.id?.trim())
                          ? "iconsstyleliked"
                          : ""
                      }`}
                      onClick={() => {
                        if (
                          !likeIndexCollection.includes(activity.id?.trim())
                        ) {
                          callSendDisikeFeedbackEvent(activity);
                        }
                      }}
                      alt="dislike"
                    />
                  </div>
                </>
              );
            })}
            {/* Scroll Target Div */}
            <div ref={scrollTargetRef} style={{ height: "1px" }} />
          </div>
        </div>
        <div className="sticky-bottom">
          {isRecording ? (
            <div className="stickystatus">
              <div className="listening-container">
                <span className="spanlist">Listening</span>
                <div className="wave">
                  <div className="bar"></div>
                  <div className="bar"></div>
                  <div className="bar"></div>
                  <div className="bar"></div>
                  <div className="bar"></div>
                </div>
              </div>{" "}
            </div>
          ) : // <div className="stickystatus">Listening{dots}</div>
          isTyping ? (
            <div className="stickystatus">
              <div className="listening-container">
                <span className="spanlist">Typing</span>
                <span className="wave">
                  <span>.</span>
                  <span>.</span>
                  <span>.</span>
                </span>
              </div>
            </div>
          ) : (
            <div className="stickystatus transp">Online</div>
          )}
          {/* {!isHidden ? (
            <>
              <div className="flexsuggesstions">
                <img src={chamber} className="chamber" alt="chamber"></img>

                <div
                  className="suggesstionbuttonsug"
                  onClick={() =>
                    quickClick("What is the Dubai Chamber of Digital Economy?")
                  }
                >
                  <div className="suggesstionbuttontxtsug">
                    What is the Dubai Chamber of Digital Economy?
                  </div>{" "}
                </div>

                <div
                  className="suggesstionbuttonsug"
                  onClick={() => quickClick("What is Create Apps?")}
                >
                  <div className="suggesstionbuttontxtsug">
                    What is Create Apps?
                  </div>{" "}
                </div>

                <div
                  className="suggesstionbuttonsug"
                  onClick={() => quickClick("What Programs do you have?")}
                >
                  <div className="suggesstionbuttontxtsug">
                    What Programs do you have?
                  </div>{" "}
                </div>

                <div
                  className="suggesstionbuttonsug"
                  onClick={() =>
                    quickClick("Can Business in Dubai Program help me?")
                  }
                >
                  <div className="suggesstionbuttontxtsug">
                    Can Business in Dubai Program help me?
                  </div>{" "}
                </div>
              </div>{" "}
            </>
          ) : (
            <></>
          )} */}

          {/* <CustomDictationInterims
            className="App-speech-interims"
            onInterimChange={handleInterimChange}
          /> */}

          <div className="sendbox">
            <input
              type="text"
              value={userMessage}
              onChange={(e) => setUserMessage(e.target.value)}
              onKeyDown={handleKeyDown}
              placeholder="Ask the AI Tool About Our Programs"
              className="sendinput"
            ></input>
            {/* {isAudio ? (
              <>
                {" "}
                <CustomDictationInterims
                  className="App-speech-interims"
                  onInterimChange={handleInterimChange}
                />
                <CustomMicrophoneButton />
              </>
            ) : (
              <></>
            )} */}

            {isAudio ? (
              <>
                {!isRecording ? (
                  <img
                    className="microphone-icon_dictating"
                    src={MicrophoneIcon}
                    onClick={handleStartDictate}
                    // onMouseDown={handleMouseDown}
                    // onMouseUp={handleMouseUp}
                    // // onMouseLeave={handleMouseUp}
                    // onTouchStart={handleMouseDown}
                    // onTouchEnd={handleMouseUp}
                    alt="microphone"
                  />
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
            {isAudio && isRecording ? (
              <img
                className={userMessage ? "sendimgActive" : "sendimg"}
                src={userMessage ? activesendButton : sendButton}
                onClick={handleSendMessage}
                alt="send"
              />
            ) : (
              <></>
            )}
            {!isAudio ? (
              <img
                className={userMessage ? "sendimgActive" : "sendimg"}
                src={userMessage ? activesendButton : sendButton}
                onClick={handleSendMessage}
                alt="send"
              />
            ) : (
              <></>
            )}

            {/* <img
              className="sendimg"
              src={userMessage ? activesendButton : sendButton}
              onClick={handleSendMessageempty}
            /> */}
          </div>
          <div className="infosmall">
            This tool is AI Powered. Check important info.
          </div>
        </div>
        <div>
          <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
            <div className="modal-overlay">
              <div className="modal-content">
                <div className="flexheadshare">
                  {" "}
                  <div className="shareTitle">Share</div>
                  <div className="close-button" onClick={handleCloseModal}>
                    &times;
                  </div>
                </div>
                <div className="shareflex">
                  <div className="flexiconx">
                    <WhatsappShareButton url="google.com" title={title}>
                      <WhatsappIcon size={42} round={true} />
                    </WhatsappShareButton>
                    <div className="lablshare">Whatsapp</div>
                  </div>

                  <div className="flexiconx">
                    <TwitterShareButton url="google.com" title={title}>
                      <img src={twitter} className="sharicons"></img>
                    </TwitterShareButton>{" "}
                    <div className="lablshare">X</div>
                  </div>

                  <div className="flexiconx">
                    <TelegramShareButton url="google.com" title={title}>
                      <img src={telegram} className="sharicons"></img>
                      {/* <TelegramIcon size={32} round={true} /> */}
                    </TelegramShareButton>{" "}
                    <div className="lablshare">Telegram</div>
                  </div>

                  <div className="flexiconx">
                    <EmailShareButton
                      url="google.com"
                      body={title}
                      subject="Dubai Chamber"
                    >
                      <img src={emailicon} className="sharicons"></img>
                    </EmailShareButton>{" "}
                    <div className="lablshare">Email</div>
                  </div>
                </div>{" "}
              </div>
            </div>
          </Modal>

          <Modal isOpen={isChatModalOpen} onClose={handleCloseChatModal}>
            <div className="modal-overlay">
              <div className="modal-contentChat">
                <div className="chatmodelbg">
                  <div className="startTitle">Start New Chat?</div>

                  <div className="desc1">
                    This will end your current conversation and clear all
                    messages. Do you wish to continue?
                  </div>
                  <div className="flexbuttons flexbuttons2x">
                    <div className="cancelbtn" onClick={handleCloseChatModal}>
                      {" "}
                      <div className="cancelbtntxt">Cancel</div>
                    </div>

                    <div className="yesbtn" onClick={newChatClick}>
                      {" "}
                      <div className="yesbtntxt">Yes</div>
                    </div>
                  </div>
                </div>{" "}
              </div>{" "}
            </div>
          </Modal>

          <Modal isOpen={isEmailodalOpen} onClose={handleCloseEmailModal}>
            {!summaryFlag ? (
              <div className="modal-overlay">
                <div className="modal-emailChat">
                  <div className="chatmodelbg">
                    <div className="startTitle1">Send Chat Summary?</div>
                    <div className="desc2">
                      Enter your email below to receive a summary of this
                      conversation.
                    </div>
                    {isValid ? (
                      <div className="invalidMessage validmsg">Valid</div>
                    ) : (
                      <div className="invalidMessage">* Invalid email</div>
                    )}
                    <input
                      type="email"
                      className="inputMessage"
                      placeholder="email@example.com"
                      onChange={handleEmailChange}
                    />

                    {!loader ? (
                      <div className="flexbuttonsemail">
                        <div className="cancelbtn" onClick={callCancelEmail}>
                          <div className="cancelbtntxt">Cancel</div>
                        </div>
                        {isValid ? (
                          <div className="yesbtn" onClick={callEmailSendEvent}>
                            <div className="yesbtntxt">Send</div>
                          </div>
                        ) : (
                          <div className="yesbtn nocur">
                            <div className="yesbtntxt">Send</div>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="flexspinner">
                        <div className="loader"></div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : sentFlag ? (
              <div className="modal-overlay">
                <div className="modal-emailsucc">
                  <div className="chatmodelbg">
                    <div className="startTitle2">Success!</div>
                    <div className="desc3">
                      Chat summary was sent successfully.
                    </div>
                    <div className="flexbuttonsemail2">
                      <div className="cancelbtn2" onClick={callCancelEmail}>
                        <div className="cancelbtntxt">Back to Chat</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="modal-overlay">
                <div className="modal-emailsucc">
                  <div className="chatmodelbg">
                    <div className="startTitle3">Sending Failed!</div>
                    <div className="desc4">
                      There was an issue sending chat summary.
                    </div>

                    {!loader ? (
                      <div className="flexbuttonsemail2">
                        <div className="cancelbtn3" onClick={callCancelEmail}>
                          <div className="cancelbtntxt">Cancel</div>
                        </div>
                        <div className="yesbtn" onClick={callEmailSendEvent}>
                          <div className="yesbtntxt">Try Again</div>
                        </div>
                      </div>
                    ) : (
                      <div className="flexspinner flexspinner2">
                        <div className="loader"></div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </Modal>
        </div>
      </div>
    </>
  );

  // return (
  //   <div className="wrapper">
  //     <div className="flexheader">
  //       <div className="hiddendv"></div>
  //       <div className="fleximage">
  //         {" "}
  //         <img className="flexbgimg" src={logo} />
  //       </div>

  //       <div className="flexiconsheader">
  //         {activities.length > 0 ? (
  //           <>
  //             <img
  //               className="mailimg"
  //               src={whitemail}
  //               onClick={handleOpenEmailModal}
  //             />
  //             <img
  //               className="plusimg"
  //               src={whiteplus}
  //               onClick={handleOpenChatModal}
  //             />
  //           </>
  //         ) : (
  //           <>
  //             <img className="mailimghidden" src={whitemail} />
  //             <img className="plusimghidden" src={whiteplus} />
  //           </>
  //         )}
  //       </div>
  //     </div>

  //     <main className="content">
  //       <p>
  //         This is the content section. It will take the remaining space between
  //         the header and footer and will be scrollable if the content overflows.
  //       </p>
  //       <p>
  //         Add more content here to see the scroll effect. Lorem ipsum dolor sit
  //         amet, consectetur adipiscing elit. Vivamus luctus urna sed urna
  //         ultricies ac tempor dui sagittis. In condimentum facilisis porta. Sed
  //         nec diam eu diam mattis viverra. Nulla fringilla, orci ac euismod
  //         semper, magna diam porttitor mauris, quis sollicitudin sapien justo in
  //         libero. Mauris faucibus bibendum urna, sed sollicitudin nisi tristique
  //         a. Integer nec ipsum a arcu cursus vestibulum.
  //       </p>
  //       <p>Add more content here to see the scroll effect.</p>
  //     </main>

  //     <footer className="footer">
  //       <p>Footer</p>
  //     </footer>
  //   </div>
  // );
};

export default memo(ChatBot);
