import {
  Components,
  createDirectLine,
  createStore,
} from "botframework-webchat";
import * as SpeechSDK from "microsoft-cognitiveservices-speech-sdk";

import React, { memo, useEffect, useState, useCallback, useRef } from "react";

import fetchDirectLineToken from "./fetchDirectLineToken";
import ChatBot from "./ChatBot";

import { createCognitiveServicesSpeechServicesPonyfillFactory } from "botframework-webchat";
import { setTextRange } from "typescript";
import Minimize from "./Minimize";
import Chatform from "./Chatform";

const { Composer } = Components;

function App() {
  const Speechservicekey = process.env.REACT_APP_API_Speechservicekey || "";
  const AzureRegion = "uaenorth"; // Your Azure Speech Service region

  const [webChatProps, setWebChatProps] = useState<{
    directLine: ReturnType<typeof createDirectLine>;
    webSpeechPonyfillFactory: ReturnType<
      typeof createCognitiveServicesSpeechServicesPonyfillFactory
    >;
  } | null>(null);

  const [recognizer, setRecognizer] =
    useState<SpeechSDK.SpeechRecognizer | null>(null);
  const [recognizedText, setRecognizedText] = useState<string>("");
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const [isListening, setIsListening] = useState(false); // Control mic state

  useEffect(() => {
    (async () => {
      const directLineToken = await fetchDirectLineToken();

      // Create Web Chat properties but DON'T start speech recognition yet
      setWebChatProps({
        directLine: createDirectLine({
          token: directLineToken,
        }),
        webSpeechPonyfillFactory:
          await createCognitiveServicesSpeechServicesPonyfillFactory({
            credentials: {
              region: AzureRegion,
              subscriptionKey: Speechservicekey,
            },
            enableTelemetry: true,
          }),
      });
    })();
  }, []);
  const [lastRecognizedText, setLastRecognizedText] = useState("");
  const [currentRecognizer, setCurrentRecognizer] =
    useState<SpeechSDK.SpeechRecognizer | null>(null);
  const [sessionId, setSessionId] = useState<string | null>(null);
  const recognizedTextRef = useRef("");

  const startDictate = useCallback(() => {
    if (recognizer || isListening) return; // Avoid multiple recognizers

    console.log("Starting speech recognition...");

    const speechConfig = SpeechSDK.SpeechConfig.fromSubscription(
      Speechservicekey,
      AzureRegion
    );
    speechConfig.speechRecognitionLanguage = "ar-SA";

    speechConfig.setProperty(
      SpeechSDK.PropertyId.SpeechServiceConnection_EndSilenceTimeoutMs,
      "60000"
    );

    const audioConfig = SpeechSDK.AudioConfig.fromDefaultMicrophoneInput();
    const speechRecognizer = new SpeechSDK.SpeechRecognizer(
      speechConfig,
      audioConfig
    );

    // Optionally, detect when the session is fully completed
    speechRecognizer.recognized = (s, e) => {
      console.log("Recognized:", e.result.text);
      recognizedTextRef.current = e.result.text.trim().replace(/\.$/, ""); // Store recognized text without triggering re-render
      setRecognizedText(recognizedTextRef.current);
      if (e.result.reason === SpeechSDK.ResultReason.RecognizedSpeech) {
        console.log("Full sentence recognized:", e.result.text);

        setLastRecognizedText(
          (prevText) =>
            `${prevText} ${recognizedTextRef.current.trim().replace(/\.$/, "")}`
        );

        // Force re-render after updating ref
        setTimeout(() => {
          setRecognizedText(""); // Clear after ensuring UI update
        }, 100);
      } else {
        console.log("Recognition failed or no speech detected.");
      }
    };

    // Stop recognition after 60000ms silence
    speechRecognizer.sessionStopped = () => {
      console.log("Silence detected, restarting recognition...");

      if (recognizedText) {
        setRecognizedText(""); // Reset text after sending
        setLastRecognizedText(""); // Reset text after sending
      }
    };

    speechRecognizer.startContinuousRecognitionAsync();
    setRecognizer(speechRecognizer);
    setIsListening(true);
    console.log("Speech recognition started.");
  }, [recognizer, isListening, recognizedText]);

  const stopDictate = useCallback(() => {
    if (recognizer) {
      console.log("Stopping speech recognition...");
      recognizer.stopContinuousRecognitionAsync();
      setRecognizer(null);
      setIsListening(false);
      setRecognizedText("");
      setLastRecognizedText(""); // Reset text after sending
    }
  }, [recognizer]);

  // Create a middleware to block auto-submission of speech messages
  // const store = createStore(
  //   {},
  //   () => (next: (action: any) => void) => (action: any) => {
  //     console.log("Intercepted action v.", action.type);
  //     console.log("Intercepted action v.", action);

  //     let method = "";
  //     if ("payload" in action && action.payload?.method) {
  //       method = action.payload.method;
  //     }
  //     if (action.type === "WEB_CHAT/SUBMIT_SEND_BOX" && method === "speech") {
  //       console.log(
  //         "Intercepted speech submission. Blocking message from being sent."
  //       );

  //       return null; // Prevent the action from being dispatched
  //     }

  //     return next(action); // Pass other actions through
  //   }
  // );


  const [isMinimized, setIsMinimized] = useState(false);

  const handleToggle = () => {
    setIsMinimized(!isMinimized);
  };

  const [toggleStateMini, setToggleStateMini] = useState(false);
  const handleToggleChange = (newToggleState: boolean) => {
    setToggleStateFull(false);
    setToggleStateMini(true);
    console.log("Toggle State app:", newToggleState);
  };

  const [toggleStateFull, setToggleStateFull] = useState(true);
  const handleToggleFullChange = (newToggleState: boolean) => {
    setToggleStateFull(true);
    setToggleStateMini(false);

    console.log("Toggle State app:", newToggleState);
  };

  return (
    <>

      {/* {webChatProps && (
        <Composer {...webChatProps}>
          {toggleStateMini ? (
            <Minimize onToggleChange={handleToggleFullChange} />
          ) : (
            <></>
          )}

          {toggleStateFull ? (
            <Chatform onToggleChange={handleToggleChange} />
          ) : (
            <></>
          )}
        </Composer>
      )} */}

      {webChatProps && (
        <Composer {...webChatProps} locale="ar-SA">
          <ChatBot
            recognizedText={recognizedText}
            lastRecognizedText={lastRecognizedText}
            startDictate={startDictate}
            stopDictate={stopDictate}
          />
        </Composer>
      )}
    </>
  );
}

export default memo(App);
